import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 12px;
  padding-bottom: 31px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding-bottom: 20px;

    .articles-wrapper {
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
      column-gap: 12px;  
      padding-top: 12px;  
    }
    .image_wrapper {
      display: block;
      img {
        border-radius: 16px 16px 0 0; 
        width: 100%;
      }
    }
  }
`;

export const ArticlesWrapper = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 12px;

`;

export const MPUWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;