import React, { FC } from "react";
import {Wrapper, MPUWrapper, ArticlesWrapper} from "./HeroPlus6.styled";
import MPU from "common/Layout/Adverts/MPU";
import Hero from "Components/Hero";
import Item from "Components/Item";
import { ArticleComponent } from "../types";

type Props = {
  data: ArticleComponent;
};

const getCropData = (article: any, size: string) => article?.hero?.[0]?.data?.extra?.sizes?.[size];

const HeroPlus6: FC<Props> = ({ data }) => {
  const [mainArticle, ...articles] = data.articles;

  return (
    <Wrapper className={'hero6-only'}>
      <Hero size={6} article={mainArticle} cropData={getCropData(mainArticle, "threebytwo")} />
      <ArticlesWrapper  className="articles-wrapper">
        {articles.map((article, index) => (
          <Item
            key={index}
            size={6}
            article={article}
            cropData={getCropData(article, "onebyone")}
          />
        ))}
      </ArticlesWrapper>
    </Wrapper>
  );
};

export default HeroPlus6;